import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto nacl sign`}</strong>{` -- sign small messages using public-key cryptography`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto nacl sign <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto nacl sign`}</strong>{` command group uses public-key cryptography to sign and
verify messages. The implementation is based on NaCl's crypto_sign function.`}</p>
    <p>{`NaCl crypto_sign is crypto_sign_edwards25519sha512batch, a particular
combination of Curve25519 in Edwards form and SHA-512 into a signature scheme
suitable for high-speed batch verification. This function is conjectured to meet
the standard notion of unforgeability under chosen-message attacks.`}</p>
    <p>{`These commands are interoperable with NaCl: `}<a parentName="p" {...{
        "href": "https://nacl.cr.yp.to/sign.html"
      }}>{`https://nacl.cr.yp.to/sign.html`}</a></p>
    <h2>{`Examples`}</h2>
    <p>{`Create a keypair for verifying and signing messages:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto nacl sign keypair nacl.sign.pub nacl.sign.priv
`}</code></pre>
    <p>{`Sign a message using the private key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto nacl sign sign nacl.sign.priv
Please enter text to sign: 
rNrOfqsv4svlRnVPSVYe2REXodL78yEMHtNkzAGNp4MgHuVGoyayp0zx4D5rjTzYVVrD2HRP306ZILT62ohvCG1lc3NhZ2U

$ cat message.txt | step crypto nacl sign sign ~/step/keys/nacl.recipient.sign.priv
rNrOfqsv4svlRnVPSVYe2REXodL78yEMHtNkzAGNp4MgHuVGoyayp0zx4D5rjTzYVVrD2HRP306ZILT62ohvCG1lc3NhZ2U
`}</code></pre>
    <p>{`Verify the signed message using the public key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo rNrOfqsv4svlRnVPSVYe2REXodL78yEMHtNkzAGNp4MgHuVGoyayp0zx4D5rjTzYVVrD2HRP306ZILT62ohvCG1lc3NhZ2U \\
     | step crypto nacl sign open nacl.sign.pub
message
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "keypair/"
              }}>{`keypair`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`generate a pair for use with sign and open`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "open/"
              }}>{`open`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`verify a signed message produced by sign`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "sign/"
              }}>{`sign`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sign a message using Ed25519`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      